import Cookies from 'js-cookie';
import axios from 'axios';
import { Navigate, Outlet } from 'react-router-dom';
import { routesPath } from '../utils';

const { TOKEN } = routesPath;
function PrivateRoute({ children }) {
  // const token = localStorage.getItem("token");
  const location = window.location.pathname.replace("/", "");
  let token = Cookies.get(TOKEN);

  axios.defaults.headers.common['Authorization'] = token;
  if (!token) {
    sessionStorage.setItem('rememberLoginUser',location)
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" />;
  }

  // authorized so return child components
  // return children;
  return <Outlet />;
}

export { PrivateRoute };
