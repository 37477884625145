import { useFormik } from "formik";
import { AppContainer } from "../../atoms";
import { PageTabIndicator } from "../../components";
import { useState, useRef, useEffect } from "react"
import PinInput from "react-pin-input";
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { Button, Switch } from '../../components';
import { ZojaButton, ZojaModal, ZojaSelect } from "../../components/tailwind";
import { generateSetup2faToken, complete2fa } from '../../redux/slice';
import successGifImage from "../../assets/gif/success-blue.gif"
import Cookies from "js-cookie"
const twofaEnabled = Cookies.get('is_otp_enabled');
export default function SecuritySettings() {
    const [twoFaStatus, setTwoFaStatus] = useState<any>(twofaEnabled === 'true' ? true : false)
    const [otp, setOtp] = useState("")
    const otpRef = useRef<PinInput>(null)
    const [showOtpError, setShowOtpErr] = useState<boolean>(false)
    const dispatch = useAppDispatch();
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [show2faModal, setShow2faModal] = useState(false)
    const tokenState = useAppSelector(state => state.verify2fa);
    const complete2faState = useAppSelector(state => state.complete2fa)
    const onChange = (checked: boolean) => {
        setTwoFaStatus(checked)
        setShow2faModal(true)
        initiate2faToken()

    };



    const handleClear = () => {
        setShow2faModal(false)
        if (otpRef.current) {
            otpRef.current.clear(); // Assuming the component has a `clear()` method
        }
    };

    const confirmOtp = async (e: any) => {
        e.preventDefault()
        if (!otp) {
            setShowOtpErr(true)
            return;
        }
        const payload = {
            email: Cookies.get('email'),
            token: otp,
            is_2fa_enabled: twoFaStatus

        }
        await dispatch(complete2fa(payload));

    }

    useEffect(() => {
        if (otp) {
            setShowOtpErr(false)
        }
    }, [otp])

    useEffect(() => {
        if (complete2faState?.status === "succeeded")
            setShowSuccessModal(true)
    }, [complete2faState?.status])

    useEffect(() => {
        if (complete2faState?.status === 'succeeded') {
            setShow2faModal(false)
            handleClear()
        }
    }, [complete2faState])


    const initiate2faToken = async () => {
        await dispatch(generateSetup2faToken('null'));
    }

    return (
        <div>
            <AppContainer navTitle={
                <span className="tw-ml-3 tw-text-[#5E6366] tw-text-xs tw-font-medium md:tw-text-[1.2rem]">Settings</span>
            }

            >
                <div>
                    <section className='tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-zojaShadowOne tw-w-full mt-5'>
                        <div className="tw-flex tw-items-center tw-justify-between md:tw-items-baseline">
                            <div
                                style={{ width: 'fit-content', borderBottom: '3px solid #222B88' }}

                                className={`tw-block tw-cursor-pointer tw-min-w-fit tw-text-sm tw-no-underline hover:tw-text-[#222B88] md:tw-text-[16px] md:tw-overflow-auto tw-text-[#222B88] tw-font-medium tw-border-b-2 tw-border-b-[#222B88]'
                                
                            `}
                            >
                                Security Settings
                            </div>
                        </div>
                        <br />
                        <h2 className='tw-text-dark  tw-font-normal tw-text-sm tw-mt-4 tw-mb-0 md:tw-text-[1.2rem]'>Two Factor Authentication (2FA)</h2>
                        <br />
                        <small>Set up two factor authentication functionality for when you want to login for extra user account security</small>
                        <br />
                        <div className="py-4">
                            <div className="d-flex align-items-center">
                                <small className="mr-3">Enable 2FA option for Login on (BACK-OFFICE){" "}</small>
                                <div className="" style={{ marginLeft: '2rem' }}>
                                    <Switch checked={twoFaStatus} onChange={onChange} />

                                </div>

                            </div>

                        </div>

                    </section>
                    <ZojaModal
                        show={show2faModal}
                        handleClose={() => {
                            setShow2faModal(false)
                            handleClear()
                        }}
                        height='auto'
                        position='center'
                        extraClass="md:tw-w-[30rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                        borderRadius=".5rem"
                    >
                        <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                            <br />
                            <h4 className='primary-bold'>
                                Two Factor Authentication
                            </h4>
                            <div className="py-4">A verification code as been sent to your email. Enter the 6 digit code sent to your email below.</div>
                            <div className="mb-3 d-flex justify-content-center align-items-center">
                                <form onSubmit={confirmOtp}>

                                    <PinInput
                                        length={6}
                                        onChange={(value: any) => setOtp(value)}
                                        type="numeric"
                                        inputMode="number"
                                        style={{ padding: "10px" }}
                                        inputStyle={{ borderColor: "#F4F4F4", backgroundColor: '#F4F4F4', borderRadius: '8px', fontWeight: 500 }}
                                        inputFocusStyle={{ borderColor: "1px solid #F4F4F4" }}
                                        autoSelect={true}
                                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                        ref={otpRef} // Attach the reference
                                    />
                                    {showOtpError &&
                                        <div className='danger-text'>Token is required!</div>}
                                    <br />
                                    <div className='text-center primary-text cursor' style={{ cursor: 'pointer' }} onClick={initiate2faToken}>{tokenState.tetiary_status === 'loading' ? "Sending Code" : "Resend Code"}</div>
                                    <div className='d-flex justify-content-between align-items-center pt-5'>
                                        <div className='danger-text' onClick={handleClear} style={{ cursor: 'pointer' }}>Cancel</div>
                                        <div className='w-50'>
                                            <Button type="submit" text="Continue" disabled={complete2faState?.status === 'loading' ? true : false} />
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <br />
                        </div>
                    </ZojaModal>

                    <ZojaModal
                        show={showSuccessModal}
                        handleClose={() => {
                            setShowSuccessModal(false)
                        }}
                        height='auto'
                        position='center'
                        extraClass="md:tw-w-[30rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                        borderRadius=".5rem"
                    >
                        <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                            <br />
                            <br />
                            <div className='d-flex justify-content-center'>
                            <img src={successGifImage} width={"50%"} />
                            </div>
                            <h5 className="text-center">Success</h5>
                            <p className="py-4 text-center">All your changes have been implemented and preferences have been saved.</p>
                            <div className='d-flex justify-content-center'>
                            <div className='w-50 '>
                                <Button type="submit" text="Complete" onClick={() => {
                                    setShowSuccessModal(false)

                                }} />
                                <br />
                            </div>
                            </div>

                        </div>
                    </ZojaModal>
                </div>

            </AppContainer>

        </div>
    )
}
