import * as yup from 'yup';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { Container, ContainerContent, Content, SwitchCard } from './style';
import { Button, Input, Switch } from '../../components';
import { colors, spacing } from '../../utils';
import { H2 } from '../../styles';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { loginRequest, verifyLoginReq, resend2faToken } from '../../redux/slice';
import { routesPath } from '../../utils';
import PinInput from "react-pin-input";
import { showMessage } from "../../utils"
import Cookies from 'js-cookie';
import { ZojaButton, ZojaModal, ZojaSelect } from "../../components/tailwind";

const { DASHBOARD, REMEMBERUSER, TOKEN } = routesPath;
function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const otpRef = useRef<PinInput>(null)


  const [switchChecked, setSwitchChecked] = useState(false);
  const [rememberedUserEmail, setRememberedUserEmail] = useState('');
  const [showOtpError, setShowOtpErr] = useState<boolean>(false)
  const [otp, setOtp] = useState(null)
  const [email, setEmail] = useState("")
  const loginState = useAppSelector(state => state.login);
  const verify2fa = useAppSelector(state => state.verify2fa);
  const [show2faModal, setShow2faModal] = useState(false)


  const handleClear = () => {
    setShow2faModal(false)
    if (otpRef.current) {
      otpRef.current.clear(); // Assuming the component has a `clear()` method
    }
  };


  const { status } = loginState;

  console.log(loginState)

  const schema = yup.object().shape({
    email: yup.string().email().required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  useLayoutEffect(() => {
    const cookiesEmail = Cookies.get(REMEMBERUSER);

    if (typeof cookiesEmail === 'string' && cookiesEmail.length > 4) {
      setSwitchChecked(true);
      setRememberedUserEmail(cookiesEmail);
    } else {
      setSwitchChecked(false);
      setRememberedUserEmail('');
    }
  }, []);

  const confirmOtp = async (e: any) => {
    e.preventDefault()
    if (!otp) {
      setShowOtpErr(true)
      return;
    }
    const payload = {
      email: email,
      token: otp
    }
    await dispatch(verifyLoginReq(payload));
  }



  useEffect(() => {
    if (otp) {
      setShowOtpErr(false)

    }
  }, [otp])

  useEffect(() => {

    if (loginState?.status === 'succeeded' && loginState?.data?.is_otp_enabled === true) {
      setShow2faModal(true)
    }
  }, [loginState]);


  useEffect(() => {
    const cookiesToken = Cookies.get(TOKEN);

    if (cookiesToken !== undefined) {
      if (sessionStorage?.getItem("rememberLoginUser")) {
        window.location.href = '/' + sessionStorage?.getItem("rememberLoginUser")
      }
      else {
        window.location.href = DASHBOARD
      }
    }
  }, [loginState]);

  useEffect(() => {
    const cookiesToken = Cookies.get(TOKEN);

    if (cookiesToken !== undefined) {
      if (sessionStorage?.getItem("rememberLoginUser")) {
        window.location.href = '/' + sessionStorage?.getItem("rememberLoginUser")
      }
      else {
        window.location.href = DASHBOARD
      }
    }
  }, [verify2fa]);




  return (
    <div>
      <Formik
        initialValues={{ email: rememberedUserEmail, password: '' }}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          const { email, password } = values;
          setEmail(email)
          const payload = {
            email: email.trim(),
            password: password.trim(),
            rememberUser: switchChecked,
          };
          await dispatch(loginRequest(payload));
          setSubmitting(false);
        }}>
        {formikProps => {
          const { handleChange, values, handleSubmit, errors } = formikProps;
          return (
            <Container>
              <ContainerContent>
                <Content>
                  <H2 center bold color={colors.primary} style={{ marginBottom: spacing.medium }}>
                    Login
                  </H2>
                  <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: spacing.medium }}>
                      <Input
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email}
                        backgroundColor={colors.white}
                      />
                    </div>

                    <Input
                      label="Password"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      error={errors.password}
                      backgroundColor={colors.white}
                    />
                    <SwitchCard>
                      <Switch
                        onChange={() => setSwitchChecked(!switchChecked)}
                        checked={switchChecked}
                        label="Remember me"
                      />
                    </SwitchCard>
                    <Button type="submit" text="Login" disabled={status === 'loading' ? true : false} />
                  </form>
                </Content>
              </ContainerContent>
            </Container>
          );
        }}
      </Formik>
      <ZojaModal
        show={show2faModal}
        handleClose={() => {
          setShow2faModal(false)
        }}
        height='auto'
        position='center'
        extraClass="md:tw-w-[30rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
        borderRadius=".5rem"
      >
        <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
          <br />
          <h4 className='primary-bold'>
            Two Factor Authentication
          </h4>
          <div className="py-4">A verification code as been sent to your email. Enter the 6 digit code sent to your email below.</div>
          <div className="mb-3 d-flex justify-content-center align-items-center">
            <form onSubmit={confirmOtp}>

              <PinInput
                length={6}
                onChange={(value: any) => setOtp(value)}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                inputStyle={{ borderColor: "#F4F4F4", backgroundColor: '#F4F4F4', borderRadius: '8px', fontWeight: 500 }}
                inputFocusStyle={{ borderColor: "1px solid #F4F4F4" }}
                autoSelect={true}
                ref={otpRef}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              {showOtpError &&
                <div className='danger-text'>Token is required!</div>}
              <br />
              <div style={{ cursor: 'pointer' }} className='text-center primary-text cursor' onClick={async () => {

                await dispatch(resend2faToken(email));

              }}>{verify2fa?.secondary_status === 'loading' ? 'Sending Code' : 'Resend Code'}</div>
              <div className='d-flex justify-content-between align-items-center pt-5'>
                <div className='danger-text' onClick={handleClear} style={{ cursor: 'pointer' }}>Cancel</div>
                <div className='w-50'>
                  <Button type="submit" text="Continue" disabled={verify2fa?.status === 'loading' ? true : false} />
                </div>
              </div>
            </form>

          </div>
          <br />
        </div>
      </ZojaModal>
    </div>
  );
}

export default Login;
