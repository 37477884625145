import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';
import { authRequest } from './authSlice';
import { routesPath, combinePermissions, showMessage } from '../../utils';
export interface verifyLoginType {
    email: string;
    token: any;
}
type Dictionary = {
    [key: string]: any;
};

interface InitState {
    data: Dictionary;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    secondary_status: 'idle' | 'loading' | 'succeeded' | 'failed';
    tetiary_status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: any;
}

const initialState = {
    data: {},
    status: 'idle',
    secondary_status:'idle',
    tetiary_status:'idle',
    error: null,
} as InitState;

const { TOKEN, REMEMBERUSER } = routesPath;


export const generateSetup2faToken = createAsyncThunk('2fa-token-generate', async (payload: any, { dispatch }) => {
    try {

        const response = await api.post('admin/two-fa/initiate', {});

        const { token, user } = response.data;
       

        return response?.data;
    } catch (err) {
        throw err;
    }
});


export const resend2faToken = createAsyncThunk('2fa-token-resend', async (payload: any, { dispatch }) => {
    try {
        // for the actual login api

        const response = await api.post('admin/resend-two-fa-token', { email: payload });

        const { token, user } = response.data;
        showMessage({
            type: 'success',
            message: response.data?.message
        })

        return response?.data;
    } catch (err) {
        throw err;
    }
});


export const verifyLoginReq = createAsyncThunk('verify-login', async (payload: verifyLoginType, { dispatch }) => {
    try {

        const response = await api.post('admin/verify-two-fa-token', payload);

        const { token, user } = response.data;
        type UserPermissions = {
            id: number;
            name: string;
        };

        const rememberedEmail = user.email;
        const rememberedLogId = user.id;
        Cookies.set(TOKEN, token);
        const userNames: string[] = user.permissions.map((user: UserPermissions) => user.name);
        const eligibleLevelOneApproval = userNames.includes("can approve level one transaction");
        const eligibleLevelTwoApproval = userNames.includes("can approve level two transaction");
        const userRole = user?.roles[0]?.name;
        Cookies.set("eligibleLevelOneApproval", String(eligibleLevelOneApproval));
        Cookies.set("eligibleLevelTwoApproval", String(eligibleLevelTwoApproval));
        Cookies.set("x-id", rememberedLogId)
        Cookies.set("user-role", userRole)
        Cookies.set("email", user?.email)
        Cookies.set("is_otp_enabled",user?.is_2fa_enabled === 1 ? 'true' : 'false')
        dispatch(authRequest(user));

        return response?.data;
    } catch (err) {

        throw err;
    }
});

const verifyLoginSlice = createSlice({
    name: 'verify-login',
    initialState,
    reducers: {
        reset: state => {
            Object.assign(state, initialState);
        },
        updateVerifyLoginData: (state, action) => {
            state.data.user = { ...state.data.user, [action.payload.key]: action.payload.value };
        },
    },
    extraReducers: builder => {
        builder.addCase(verifyLoginReq.pending, state => {
            state.status = 'loading';
        });
        builder.addCase(verifyLoginReq.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(verifyLoginReq.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        });
        builder.addCase(resend2faToken.pending, state => {
            state.secondary_status = 'loading';
        });
        builder.addCase(resend2faToken.fulfilled, (state, action) => {
            state.secondary_status = 'succeeded';
            state.error = null;
        });
        builder.addCase(resend2faToken.rejected, (state, action) => {
            state.secondary_status = 'failed';
            state.error = action.payload;
        });

        builder.addCase(generateSetup2faToken.pending, state => {
            state.tetiary_status = 'loading';
        });
        builder.addCase(generateSetup2faToken.fulfilled, (state, action) => {
            state.tetiary_status = 'succeeded';
            state.error = null;
        });
        builder.addCase(generateSetup2faToken.rejected, (state, action) => {
            state.tetiary_status = 'failed';
            state.error = action.payload;
        });
    },
});

export const verifyLoginReset = verifyLoginSlice.actions.reset;
export const updateVerifyLoginData = verifyLoginSlice.actions.updateVerifyLoginData;
export const verifyLoginReducer = verifyLoginSlice.reducer;
