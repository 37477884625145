import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';
import { authRequest } from './authSlice';
import { routesPath, combinePermissions, showMessage } from '../../utils';
export interface verify2faType {
    email:any; 
    is_2fa_enabled: boolean;
    token:any;
}
type Dictionary = {
    [key: string]: any;
};

interface InitState {
    data: Dictionary;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: any;
}

const initialState = {
    data: {},
    status: 'idle',
    error: null,
} as InitState;




export const complete2fa = createAsyncThunk('complete-2fa', async (payload: verify2faType, { dispatch }) => {
    const { email, token,is_2fa_enabled } = payload;
    try {
        // for the actual login api
        const payloadData = {
            email, 
            is_2fa_enabled,
            token,
        };
        const response = await api.post('admin/two-fa/setup', payloadData);
       
        return response?.data;
    } catch (err) {
        throw err;
    }
});

const complete2faSlice = createSlice({
    name: 'comeplte-2fa',
    initialState,
    reducers: {
        reset: state => {
            Object.assign(state, initialState);
        },
        updateComplete2faData: (state, action) => {
            state.data.user = { ...state.data.user, [action.payload.key]: action.payload.value };
        },
    },
    extraReducers: builder => {
        builder.addCase(complete2fa.pending, state => {
            state.status = 'loading';
        });
        builder.addCase(complete2fa.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(complete2fa.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        });
    },
});

export const complete2faReset = complete2faSlice.actions.reset;
export const updateComplete2faData = complete2faSlice.actions.updateComplete2faData;
export const complete2faReducer = complete2faSlice.reducer;
